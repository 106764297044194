import api from "../axios_service.js";

export async function getObservacoesGerais() {
    const response = await api.get("/observacoes-gerais");
    return response.data.result;
}

export async function getObservacoesGeraisByOrigemId(origem, origem_id) {
    const response = await api.get(`/observacoes-gerais/${origem}/${origem_id}`);
    return response.data.result;
}

export async function fetchObservacaoGeral(id) {
    const response = await api.get(`/observacoes-gerais/${id}`);
    return response.data.result;
}

export async function postObservacaoGeral(body) {
    const response = await api.post("/observacoes-gerais/add", body);
    return response.data;
}

export async function putObservacaoGeral(id, observacao) {
    return api.post(`/observacoes-gerais/${id}`, observacao);
}