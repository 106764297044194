<template>
  <div>
    <v-card flat height="100%">
      <v-card-title class="text-body-1 py-3">
        {{ $tc("global.observacoes-gerais") }}
        <v-spacer />
        <v-btn
          depressed
          outlined
          color="button_1"
          class="ml-2"
          small
          @click="abrirObservacao()"
        >
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ $tc("global.nova") }} {{ $tc("global.observacao") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <span>{{ $tc("global.data") }}</span>
                </th>
                <th class="text-left">
                  <span>{{ $tc("global.observacao") }}</span>
                </th>
                <th class="text-left">
                  {{ $tc("global.status") }}
                </th>
                <th class="text-left">
                  {{ $tc("global.responsavel") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="obs in observacoes" :key="obs.id">
                <td>{{ obs.data | dateFormat("dd/MM/yyyy") }}</td>
                <td>{{ obs.observacao }}</td>
                <td>{{ obs.status }}</td>
                <td>{{ obs.responsavel }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <DialogObservacao
      v-if="dialogObservacao"
      :dialogObservacao.sync="dialogObservacao"
      :origem="origem"
      :origem_id="origem_id"
      @close="closeDialogObservacao"
    />
  </div>
</template>

<script>
import { getObservacoesGeraisByOrigemId } from "@/api/observacoes-gerais/observacoes_gerais.js";
export default {
  name: "ObservacoesGerais",
  data() {
    return {
      dialogObservacao: false,
      observacoes: [],
    };
  },
  props: {
    origem: {
      type: String,
      default: "",
    },
    origem_id: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    abrirObservacao() {
      this.dialogObservacao = true;
    },
    closeDialogObservacao() {
      this.dialogObservacao = false;
      this.getObservacoes();
    },
    async getObservacoes() {
      this.observacoes = await getObservacoesGeraisByOrigemId(
        this.origem,
        this.origem_id
      );
    },
  },
  components: {
    DialogObservacao: () => import("./DialogObservacao.vue"),
  },
  async mounted() {
    this.getObservacoes();
  },
};
</script>
